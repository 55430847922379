import { makeVar } from "@apollo/client";
import { ICluster } from "../@types/graphql";

export interface IChangedRowItem {
  price: string;
  steps: number[];
  markups: number[];
  sku: number;
  id: string;
  comment?: string;
}

export interface IChangedRows {
  [p: string]: IChangedRowItem;
}

export const pricesChangedRows = makeVar<IChangedRows>({});
export const chosenPricesCluster = makeVar<ICluster | undefined>(undefined);
