import { FC } from "react";
import { CellContext } from "@tanstack/react-table";
import { IPrice } from "../../@types/graphql";
import { useReactiveVar } from "@apollo/client";
import { pricesChangedRows, IChangedRowItem } from "../../store/prices.store";
import { CustomInput } from "../../components/customInput";
import { restrictNumbers } from "../../helpers/common";

export const AdditionalCell: FC<CellContext<IPrice, unknown>> = (d) => {
  const editRows = useReactiveVar(pricesChangedRows);
  const item = d.row.original;
  const id = item.id;
  const markups = editRows?.[id]?.markups || item?.markups;
  const steps = editRows?.[id]?.steps || item?.steps;
  const rendererItems = steps.map((i, index) => ({
    step: i,
    percentages: markups?.slice(0, index + 1) || [],
  }));

  return (
    <div tabIndex={-1}>
      {rendererItems.map((i, index) => {
        const priceToShow = i.percentages.reduce(
          (prev, next) => Math.ceil(prev * (next / 100 + 1)),
          +editRows?.[id]?.price || item.price,
        );

        return (
          <div key={index} tabIndex={-1}>
            <CustomInput
              wrapperClassname="!inline-flex"
              autoWidth
              className="!text-primary700 !bg-transparent !p-0"
              value={`${i.step}`}
              onChange={(e) => {
                const prev = {
                  price: d.row.original.price,
                  sku: d.row.original.sku,
                  id: d.row.original.id,
                  markups: d.row.original.markups,
                  ...(editRows[id] || {}),
                };
                const onlyNumbersValue = Math.min(
                  +restrictNumbers(e.target.value),
                );
                const stepsToUse: IChangedRowItem["steps"] = [
                  ...(prev?.steps ? prev.steps : steps),
                ];
                stepsToUse[index] = onlyNumbersValue;

                pricesChangedRows({
                  ...editRows,
                  [id]: { ...prev, steps: stepsToUse },
                });
              }}
            />{" "}
            штук –{" "}
            <CustomInput
              wrapperClassname="!inline-flex"
              autoWidth
              className="!text-primary700 !bg-transparent !p-0"
              value={`${i.percentages[i.percentages.length - 1]}`}
              onChange={(e) => {
                const prev = {
                  price: d.row.original.price,
                  steps: d.row.original.steps,
                  id: d.row.original.id,
                  sku: d.row.original.sku,
                  comment: d.row.original?.comment || "",
                  ...(editRows[id] || {}),
                };
                const onlyNumbersValue = Math.min(
                  +restrictNumbers(e.target.value),
                );
                const markupsToUse = [
                  ...(prev?.markups ? prev.markups : markups),
                ];
                markupsToUse[index] = onlyNumbersValue;

                pricesChangedRows({
                  ...editRows,
                  [id]: { ...prev, markups: markupsToUse },
                });
              }}
            />
            % – окончательная цена {priceToShow}&nbsp;RUB
          </div>
        );
      })}
    </div>
  );
};
